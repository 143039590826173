import "./Admin.css";
import DebtController from "./Debts/DebtController";
import UserController from "./Users/UserController";

function Admin() {
	return (
		<div className='admin'>
			<div className='container'>
				<UserController />
			</div>
			<div className='container'>
				<DebtController />
			</div>
		</div>
	);
}

export default Admin;
