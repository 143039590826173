import React from "react";
import LoginAdmin from "../../components/LoginAdmin/LoginAdmin";

export default function Login() {
	return (
		<div>
			<h1>Esto es el admin de login</h1>
			<LoginAdmin />
		</div>
	);
}
