import React from 'react';

const AppsMobile = (props) => (
    <div className="container_section gracias_s2">
        <h2 className="subtitle">{props.message}</h2>
        <div className="botonera">
            <a className="store_boton" href="http://owepay.app" target="_blank" rel="noreferrer">
                <div className="icon">
                    <img src="icons/gpstore.svg" alt=""/>
                </div>
                <span>Google Play</span>
            </a>
            <a className="store_boton" href="http://owepay.app" target="_blank" rel="noreferrer">
                <div className="icon">
                    <img src="icons/apple.svg" alt=""/>
                </div>
                <span>App Store</span>
            </a>
        </div>
    </div>
);

export default AppsMobile;
