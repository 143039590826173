import '../../components/Welcome/form.css';
import React, {Component} from 'react';
import Loading from '../Loading';
import PropTypes from "prop-types";
import {postDeal, generateCode} from "../../api/Deal";
import {getDebt} from "../../api/Debt";
import RangeSlider from 'react-bootstrap-range-slider';
import {Alert, Col, Row} from "react-bootstrap";
import {getInstance} from "../../api/Instance";
import PaymentManager from "../PaymentManager";
import {MAX_PAYMENTS, MIN_PAYMENT} from "../../utils/constants";
import {t} from "i18next";


class MakeDeal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sentSuccessfully: false,
            debt: {
                fullAmount: 0
            },
            step: 1,
            initialPayment: 0,
            restPayment: 0,
            numberPayment: 1,
            code: '',
            errorMsg: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadDebt = this.loadDebt.bind(this);
        this.onChangeInitialPayment = this.onChangeInitialPayment.bind(this);
        this.stepTwoRender = this.stepTwoRender.bind(this);
        this.stepThreeRender = this.stepThreeRender.bind(this);
    }

    validation(app) {
        return app.code.length > 3;
    }

    async handleSubmit() {
        if (!this.validation(this.state)) {
            this.setState({errorMsg: 'Es necesario introducir el código recibido por email'});
            setTimeout(function () {
                this.setState({errorMsg: ''});
            }.bind(this), 5000);
            return false;
        }
        this.setState({isLoading: true});
        try {
            await postDeal(
                this.props.id,
                this.state.initialPayment,
                this.state.numberPayment,
                this.state.debt.fullAmount,
                this.state.code
            );
            this.setState({isLoading: false, sentSuccessfully: true});
        } catch (error) {
            this.setState({errorMsg: 'Ha ocurrido un error, intentelo más tarde', isLoading: false});
            setTimeout(function () {
                this.setState({errorMsg: ''});
            }.bind(this), 5000);
        }
    }

    async loadDebt() {
        this.setState({isLoading: true});
        try {
            const instance = await getInstance(this.props.id);
            const debt = await getDebt(instance.assignedDebt);
            this.setState({debt, isLoading: false, initialPayment: (debt.fullAmount + (debt.fullAmount * parseFloat("0." + MIN_PAYMENT))) / 2});
        } catch (error) {
            this.setState({errorMsg: 'Ha ocurrido un error, intentelo más tarde', isLoading: false});
            setTimeout(function () {
                this.setState({errorMsg: '', step: 1});
            }.bind(this), 5000);
        }
    }

    generateCode() {
        generateCode(this.props.id);
    }

    onChangeInitialPayment(event) {
        let value = isNaN(parseFloat(event.target.value)) ? parseFloat(this.state.initialPayment) : parseFloat(event.target.value);
        if (event.target.value > this.state.debt.fullAmount) {
            value = this.state.debt.fullAmount;
        } else if (event.target.value < 0) {
            value = 0;
        }

        this.setState({
            'initialPayment': value
        })
    }

    stepOneRender(props) {
        return (<React.Fragment>
            <div className="modal_content">
                <p className="p_incident left">{t('Vas a crear un acuerdo de pago, configúralo para resolver la incidencia')}</p>
                <button className="btn mt-4" onClick={() => {
                    props.stepHandler(2)
                }}>{t('Aceptar')}</button>
            </div>
        </React.Fragment>);
    }

    stepTwoRender(props) {
        return (<React.Fragment>
            <div className="modal_content">
                <div className="p_incident">
                    <h4 className="mb-0">{t('Configurar acuerdo')}</h4>
                    <img src='/icons/settings.svg' alt='' className="icon mr-1 mt-0"/>
                    <img src='/icons/deal.svg' className="icon ml-1 mt-0" alt=''/>
                </div>
                {this.state.errorMsg !== '' && (<Alert variant="danger">{this.state.errorMsg}</Alert>)}
                <div className="p_incident mt-2 mb-0">
                    {t('Pago inicial')}
                    <span className="amount-debt">{this.state.debt.fullAmount} €</span>
                </div>
                <RangeSlider
                    className="mt-0"
                    tooltip='on'
                    min={process.env.REACT_APP_DEAL_INITIAL_MIN}
                    max={parseInt(this.state.debt.fullAmount)}
                    value={this.state.initialPayment}

                    onChange={this.onChangeInitialPayment}
                />
                <input
                    type="number"
                    id="input-initial-payment"
                    min={parseInt(this.state.debt.fullAmount) * 0.50}
                    max={parseInt(this.state.debt.fullAmount)}
                    value={this.state.initialPayment}
                    onChange={this.onChangeInitialPayment}
                />
                <div className="p_incident mt-2 mb-0">{t('Nº de pagos mensuales')}</div>
                <RangeSlider
                    className="mt-0"
                    tooltip='on'
                    min={1}
                    max={MAX_PAYMENTS}
                    value={this.state.numberPayment}
                    onChange={event => {
                        this.setState({
                            'numberPayment': event.target.value
                        })
                    }}
                />
                <div className="p_incident mt-4 mb-0">{t('Importe pago mensual')}</div>
                <h1 className="mt-0 pt-0">
                    {((parseInt(this.state.debt.fullAmount) - this.state.initialPayment) / this.state.numberPayment).toFixed(2)} €
                </h1>

                <button className="btn mt-4" disabled={this.state.errorMsg !== ''} onClick={() => {
                    props.stepHandler(3)
                }}>{t('Aceptar')}</button>
            </div>
        </React.Fragment>);
    }

    stepThreeRender(props) {
        return (<React.Fragment>
            <div className="modal_content">
                <div className="p_incident">
                    <h4 className="mb-4">Acuerdo</h4>
                </div>
                {this.state.errorMsg !== '' && (<Alert variant="danger">{this.state.errorMsg}</Alert>)}
                <div className="p_incident mt-4 mb-0">Insertar código email</div>
                <Row className="justify-content-md-center mb-3">
                    <Col xs="12" lg="8">
                        <input type="text" className="text-center" placeholder="Código email" value={this.state.code}
                               onChange={event => {
                                   this.setState({
                                       'code': event.target.value
                                   })
                               }}/>
                    </Col>
                </Row>

                <div className="p_incident mt-4 mb-0">Importe pago inicial</div>
                <h1 className="mt-0 pt-0">{this.state.initialPayment} €</h1>
                <button className="btn mt-4" onClick={() => {
                    props.handleSubmit()
                }}>Validar acuerdo
                </button>
            </div>
        </React.Fragment>);
    }

    render() {
        const {isLoading, sentSuccessfully, step} = this.state;
        if (isLoading) {
            return <Loading/>
        } else if (sentSuccessfully) {
            return <PaymentManager debtId={this.props.debtId}/>
        } else if (step === 2) {
            return <this.stepTwoRender stepHandler={(value) => {
                this.setState({step: value});
                generateCode(this.props.id);
            }}/>
        } else if (step === 3) {
            return <this.stepThreeRender handleSubmit={this.handleSubmit}/>
        }
        return <this.stepOneRender stepHandler={(value) => {
            this.setState({step: value});
            this.loadDebt();
        }}/>
    }
}

MakeDeal.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default MakeDeal;