import '../../components/Welcome/form.css';
import React, { Component } from 'react';
import DeniedAccess from "../../layouts/DeniedAccess";
import ModalComponent from "../ModalComponent";
import PaymentManager from "../PaymentManager";
import MakeDeal from "../ModuleTwo/MakeDeal";
import PropTypes from "prop-types";
import DatePayment from "../ModuleOne/DatePayment";
import {Col, Row} from "react-bootstrap";
import {t} from "i18next";

class Resolve extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefuse: false,
            showModal: false,
            content: null
        };
        this.modalHandler = this.modalHandler.bind(this);
        this.changeView = this.changeView.bind(this);
    }

    modalHandler() {
        const { showModal, content } = this.state;
        return (
            <ModalComponent show={showModal} onHide={() => this.setState({ showModal: false })}>
                {content}
            </ModalComponent>
        );
    }

    changeView() {
        this.props.changeView('main');
    }

    render() {
        if (this.state.showRefuse) {
            return (<DeniedAccess />);
        }
        return (
            <div className="body">
                <div className="container_title">
                    <h1 className="titulo">{t('¿Qué necesitas?')}</h1>
                    <div onClick={this.changeView} className="btn_go_back">
                        <img src='icons/go_back.svg' alt='go_back' />
                    </div>
                </div>

                <Row className="content-resolve">
                    <Col xs={{ span: 8, offset: 2 }} sm={{ span: 5, offset: 3 }} lg={{ span: 5, offset: 2 }} className="center">
                        <h2 className="subtitulo mb-4">{t('Selecciona')}</h2>
                        <ul className="list_elements">
                            <li>
                                <div className="link_image" onClick={() => this.setState({'showModal': true, 'content': <DatePayment id={this.props.id}/> })}>
                                    <div className="rounded_border" />
                                    <img className="icon" src="icons/new_date.svg" alt=""/>
                                    <span>{t('Nueva fecha de pago')}</span>
                                </div>
                            </li>
                            <li>
                                <div className="link_image" onClick={() => this.setState({'showModal': true, 'content': <MakeDeal id={this.props.id} debtId={this.props.debtId}/> })}>
                                    <div className="rounded_border" />
                                    <img className="icon" src="icons/deal.svg" alt=""/>
                                    <span>{t('Crear acuerdo de pago')}</span>
                                </div>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} lg={{ span: 4, offset: 0 }} className="flex-justify-content">
                        <button className="btn" onClick={() => this.setState({'showModal': true, 'content': <PaymentManager debtId={this.props.debtId}/> })}>
                            {t('Pagar Ahora')}
                        </button>
                    </Col>
                </Row>
                <this.modalHandler />
            </div>
        );
    }
}

Resolve.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default Resolve;