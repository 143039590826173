import axios from "axios";
import { BASE_API } from "../utils/constants";

const API_URL = process.env.REACT_APP_API_URL;


export async function apiGET(url, headers, callback) {
	axios
		.get(`${BASE_API}${url}.json`, {
			headers: {
				Authorization: headers.authorization,
			},
		})
		.then((response) => callback(response.data));
}

function header(method) {
	const bearer = 'Bearer ' + 	localStorage.getItem('accessToken');
	return {
		method: method,
		headers: {
			'Authorization': bearer,
			'Content-Type': 'application/json'
		}
	};
}

export const getApi = async (path) =>
{
	try {
		const response = await fetch(API_URL + path, header('GET'));
		if(200 !== response.status){
			throw response;
		}
		return response.json();
	} catch (error) {
		throw error;
	}
}

export const patchApi = async (path, data) =>
{
	try {
		console.log('data to send VIA PATCH:', data);
		const response = await fetch(API_URL + path);
		return response.json();
	} catch (error) {
		throw error;
	}
}

export const postApi = async (path, data) =>
{
	try {
		const attributes = header('POST');
		attributes.body = JSON.stringify(data);
		const response = await fetch(API_URL + path, attributes);
		if(200 !== response.status && 201 !== response.status){
			throw response;
		}
		return response.json();
	} catch (error) {
		throw error;
	}
}
