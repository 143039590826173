import {getApi} from './api';

export const getDebt = (debtId) => new Promise((resolve, reject) =>
{
    getApi(debtId)
        .then(data => {
            return resolve(data);
        }).catch(err => {
            return reject(err);
        }
    );
});