import React, { Component } from 'react';
import ModalComponent from "../ModalComponent";
import PaymentManager from "../PaymentManager";
import PropTypes from "prop-types";
import MenuDesktop from "../Menu/MenuDesktop";
import {Col, Row} from "react-bootstrap";
import './moduleTwo.css';
import {t} from "i18next";

class ModuleTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            typeModal: null,
        };
        this.modalHandler = this.modalHandler.bind(this);
        this.itemSelectedHandler = this.itemSelectedHandler.bind(this);
    }

    modalHandler() {
        const { showModal, typeModal } = this.state;
        return (
            <ModalComponent show={showModal} onHide={() => this.setState({ showModal: false })}>
                {typeModal}
            </ModalComponent>
        );
    }

    itemSelectedHandler(item) {
        if (typeof item.to !== 'undefined') {
            this.props.loadModule(item.to);
        } else {
            this.setState({ typeModal: item.modal, showModal: true });
        }
    }

    render() {
        return (
            <div className='body'>
                <div className='container_title title_danger center text-center'>
                    <img src='icons/danger.svg' alt='danger' className='icon-danger' />
                    <h1 className='title title_module_two'>{t('Sin respuesta por su parte') }</h1>
                </div>
                <div className='container_module'>
                    <Row>
                        <Col xs={{ span: 10, offset: 1, order: 2 }} lg={{ span: 6, order: 1 }}>
                            <div className='container_section pb-4'>
                                <h2 className='subtitulo title_menu pb-4'>{t('Selecciona')}</h2>
                                <MenuDesktop id={this.props.id} debtId={this.props.debtId} type="ModuleTwo" itemSelected={this.itemSelectedHandler} />
                            </div>
                        </Col>
                        <Col xs={{ span: 10, offset: 1, order: 1 }} lg={{ span: 4, offset: 0, order: 2 }}>
                            <div className='container_section' id='contenedor-izquierda'>
                                <div className='image center mb-4'>
                                    <img src='figures/business_m2.svg' alt='business_m2' className="image-business mb-2" />
                                </div>
                                <button className='btn my-4' onClick={
                                    () => this.setState({ typeModal: <PaymentManager debtId={this.props.debtId} />, showModal: true })
                                }>
                                    {t('Pagar Ahora')}
                                </button>
                                <p className='module-comment-figure mt-4 center'>{t('¿Qué necesitas?')}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <this.modalHandler />
            </div>
        );
    }
}

ModuleTwo.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default ModuleTwo;