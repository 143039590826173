import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

import Login from '../pages/Login/Login';
import Welcome from '../pages/Client/Welcome';
import Admin from '../pages/Admin/Admin';
import Thanks from '../pages/Gracias/Gracias';
import DeniedAccess from "../layouts/DeniedAccess";
import WaitingPaymentFinish from "../layouts/WaitingPaymentFinish";
import ActionCompleted from "../layouts/ActionCompleted";

function RoutesList() {
    return (
        <Router>
            <Navbar />
            <Switch>
                <Route exact path='/'>
                    <DeniedAccess />
                </Route>
                <Route exact path='/welcome'>
                    <Welcome />
                </Route>
                <Route exact path='/login'>
                    <Login />
                </Route>
                <Route path='/admin'>
                    <Admin />
                </Route>
                <Route path='/gracias'>
                    <Thanks />
                </Route>
                <Route path='/waiting'>
                    <WaitingPaymentFinish />
                </Route>
                <Route path='/finish/(debt)?/:id?'>
                    <ActionCompleted
                        debtId={
                            window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== 'finish'
                            ? window.location.href.substring(window.location.href.lastIndexOf("/") + 1)
                            : ''
                        }
                    />
                </Route>
                <Route exact path='*'>
                    <DeniedAccess />
                </Route>
            </Switch>
            <Footer />
        </Router>
    );
}

export default RoutesList;
