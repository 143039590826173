import React from 'react';
import {Spinner} from "react-bootstrap";

const Loading = () => (
    <React.Fragment>
        <div className="loader">
            <Spinner animation="border" variant="info" />
        </div>
    </React.Fragment>
);

export default Loading;