import React, {Component, Suspense} from 'react';
import {getInstance} from '../../api/Instance';
import {Spinner} from "react-bootstrap";
import PropTypes from 'prop-types';
import DeniedAccess from "../../layouts/DeniedAccess";
import ModuleOneMain from "../ModuleOne";
import ModuleTwo from "../ModuleTwo";
import ModulePreTwo from "../ModulePreTwo";
import ModuleConflict from "../ModuleConflict";
import ModuleFinal from "../ModuleFinal";
import ModuleAvoidLiens from "../ModuleAvoidLiens";
import ActionCompleted from "../../layouts/ActionCompleted";
import {getDebt} from "../../api/Debt";
import ActionNotResolved from "../../layouts/ActionNotResolved";
import {t} from "i18next";

class WelcomeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            instance: null,
            debt: null,
            error: null,
            module: null
        };
        this.loadModuleHandler = this.loadModuleHandler.bind(this);
    }

    async componentDidMount() {
        this.setState({isLoading: true});
        try {
            const instance = await getInstance(this.props.id);
            const debt = instance ? await getDebt(instance.assignedDebt) : null;
            const error = (instance && instance.state > 5) ? true : null;
            this.setState({instance, debt, isLoading: false, error: error, module: this.moduleInstance(instance)});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    loadModuleHandler(moduleSelected) {
        const {instance} = this.state;
        if (moduleSelected === 'module-pre-two') {
            this.setState({
                module: (
                    <ModulePreTwo id={instance.id} debtId={instance.assignedDebt} loadModule={this.loadModuleHandler}/>)
            });
        } else if (moduleSelected === 'module-two') {
            this.setState({
                module: (
                    <ModuleTwo id={instance.id} debtId={instance.assignedDebt} loadModule={this.loadModuleHandler}/>)
            });
        }
    }

    moduleInstance(instance) {
        if (instance.state === 0) {
            document.title = "Comunícanos";
            return (<ModuleOneMain id={instance.id} debtId={instance.assignedDebt}/>);
        } else if (instance.state === 1) {
            document.title = "Sin respuesta por su parte";
            return (<ModuleTwo id={instance.id} debtId={instance.assignedDebt} loadModule={this.loadModuleHandler}/>);
        } else if (instance.state === 2) {
            document.title = "Evitemos un conflicto";
            return (
                <ModuleConflict id={instance.id} debtId={instance.assignedDebt} expireAt={instance.expirationDate}/>);
        } else if (instance.state === 3) {
            document.title = "Quiero resolver mi incidencia de pago";
            return (<ModuleFinal id={instance.id} debtId={instance.assignedDebt}/>);
        } else if (instance.state === 4) {
            document.title = "Evita embargos";
            return (
                <ModuleAvoidLiens id={instance.id} debtId={instance.assignedDebt} expireAt={instance.expirationDate}/>);
        }
    }

    render() {
        const {instance, debt, error, isLoading, module} = this.state;
        if ((isLoading || null === instance) && null === error) {
            return (
                <Suspense fallback={<Loading/>}>
                    <div className='text-center'>
                        <p>{t("¡Welcome!")}</p>
                        <div className="mt-4">
                            <p>{t("Loading data")}...</p>
                            <Spinner animation="border" variant="info"/>
                        </div>
                    </div>
                </Suspense>
            );
        }

        if (error || typeof instance?.error !== 'undefined') {
            return (<DeniedAccess/>);
        }

        if (
            (debt && debt.solved) ||
            (instance && (instance.state === 0 || instance.state === 1) && null !== instance.newDate)
        ) {
            document.title = t('Gestión finalizada');
            return (<ActionCompleted debtId={instance ? instance.assignedDebt : ''}/>);
        }
        if (instance.state === 5) {
            console.log(instance);

            document.title = t('Gestión no resuelta');
            return (instance.hasIncidencia.length || instance.newDate != null || instance.hasAcuerdo != null || instance.hasContacto != null) ? (
                <ActionCompleted debtId={instance ? instance.assignedDebt : ''}/>) : (
                <ActionNotResolved debtId={instance ? instance.assignedDebt : ''}/>);
        }

        return (
            <React.Fragment>
                {module}
            </React.Fragment>
        );
    }
}

function Loading() {
    return <h2>🌀 Loading...</h2>;
}


WelcomeComponent.propTypes = {
    id: PropTypes.number.isRequired
};

export default WelcomeComponent;