import React from 'react';
import {Col, Row} from "react-bootstrap";
import "./Incident.css";import {t} from "i18next";


const UnresolvedIncident = () => (
    <div className='text-center'>
        <Row className="justify-content-md-center mb-4">
            <Col xs="12" sm="6">
                <div className="unresolved">
                    <h1 className="title">{t("Incidencia")} <strong>{t("NO")}</strong> {t("resuelta")}</h1>
                </div>
            </Col>
            <Col xs="12" sm="6">
                <img src='/figures/judge.svg' alt='' />
            </Col>
        </Row>
        <div className="center mt-4">
            <h1 className="title thanks_for_using">
                {t("Gracias por usar")}
                <img src="/logo512.png" alt="" className="logo_ezpays"/>
            </h1>
        </div>
    </div>
);

export default UnresolvedIncident;