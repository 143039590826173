import "./Navbar.css";

import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: 'gb',
    },
    {
        code: 'es',
        name: 'Spanish',
        country_code: 'es',
    },
]

function Navbar() {

    const currentLanguageCode = 'en'
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
    const {t} = useTranslation()
    
    useEffect(() => {
        console.log('Setting page stuff')
        document.body.dir = currentLanguage.dir || 'es'
        document.title = t('app_title')
    }, [currentLanguage, t])

    return (
        <nav className='navbar'>
        </nav>
    );
}

export default Navbar;
