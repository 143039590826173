import {getApi, postApi} from './api';

export const getInstance = (id) => new Promise((resolve, reject) =>
{
    getApi('/instancia/'+id)
        .then(data => {
            return resolve(data);
        }).catch(err => {
            return reject(err);
        });
});

export const updateDate = (id, date) => new Promise((resolve, reject) =>
{
    if (document.getElementsByClassName('btn-close').length > 0) {
        document.getElementsByClassName('btn-close')[0].style.display = 'none';
    }

    const data = {
        'idInstancia': String(id),
        'newDate': date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
    };
    postApi('/addDate', data)
        .then(data =>
            {
                return resolve(data);
            }
        ).catch(err => {
            return reject(err);
        });
});