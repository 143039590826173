import React from 'react';
import {Modal} from "react-bootstrap";

const ModalComponent = (props) => (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
    >
        <Modal.Header closeButton />
        <Modal.Body>
            {props.children}
        </Modal.Body>
    </Modal>
);

export default ModalComponent;