import '../../components/Welcome/form.css';
import React, {Component} from 'react';
import DeniedAccess from "../../layouts/DeniedAccess";
import PaymentManager from "../PaymentManager";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import ModalComponent from "../ModalComponent";
import {FiCheck} from "react-icons/fi";
import {t} from "i18next";

class StatusDebt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefuse: false,
            showModal: false,
            content: null,
            timeLeft: null
        };
        this.modalHandler = this.modalHandler.bind(this);
        this.changeView = this.changeView.bind(this);
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({timeLeft: this.calculateTimeLeft()});
        }, 1000);
    }

    calculateTimeLeft() {
        const difference = +new Date(this.props.expiredAt) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    modalHandler() {
        const {showModal, content} = this.state;
        return (
            <ModalComponent show={showModal} onHide={() => this.setState({showModal: false})}>
                {content}
            </ModalComponent>
        );
    }

    changeView(view) {
        this.props.changeView(view);
    }

    render() {
        if (this.state.showRefuse) {
            return (<DeniedAccess/>);
        }
        return (
            <div className="body">
                <div className="container_title">
                    <h1 className="titulo">{t("Estado de la deuda")}</h1>
                    <div onClick={() => this.changeView('main')} className="btn_go_back">
                        <img src='icons/go_back.svg' alt='go_back'/>
                    </div>
                </div>

                <Row className="status-line justify-content-center d-none d-sm-flex">
                    <Col className="text-center">{t("Factura")}
                        <div className="separator"/>
                    </Col>
                    <Col className="text-center">{t("Cobro")}
                        <div className="separator"/>
                    </Col>
                    <Col className="text-center">{t("Aviso")}
                        <div className="separator"/>
                    </Col>
                    <Col className="text-center status-debt-active">
                        <FiCheck size="2em" className="debt-active-icon"/>
                        {t("Reclamación")}
                        <div className="separator"/>
                    </Col>
                    <Col className="text-center">{t("Judicial")}</Col>
                </Row>

                <Row className="d-flex d-sm-none">
                    <Col xs="2" className="text-center bill">{t("Factura")}
                        <div className="separator"/>
                    </Col>
                    <Col xs="2" className="text-center">{t("Cobro")}
                        <div className="separator"/>
                    </Col>
                    <Col xs="2" className="text-center">{t("Aviso")}
                        <div className="separator"/>
                    </Col>
                    <Col xs="2" className="text-center status-debt-active">
                        <FiCheck size="2em" className="debt-active-icon"/>
                        {t("Reclamación")}
                        <div className="separator"/>
                    </Col>
                    <Col xs="2" className="text-center judicial">{t("Judicial")}</Col>
                </Row>

                <Row className="status-line justify-content-center">
                    <Col xs="12">
                        {this.state.timeLeft &&
                            <p className="time-left">
                                <span className="time-left-mayor">{this.state.timeLeft?.days}</span>
                                <span className="time-left-minor">d</span> {this.state.timeLeft?.hours}
                                <span className="time-left-minor">h</span> {this.state.timeLeft?.minutes}
                                <span className="time-left-minor">min</span> {this.state.timeLeft?.seconds}
                                <span className="time-left-minor">seg</span>
                            </p>
                        }
                    </Col>
                    <Col xs="12">
                        <p className="p_incident">{t("Tiempo hasta iniciar la reclamación judicial")}</p>
                    </Col>
                </Row>

                <Row className="mx-1 mt-4">
                    <Col xs={{span: 12, offset: 0}} md={{span: 4, offset: 0}} className="flex-justify-content mb-4">
                        <button className="btn" onClick={() => this.setState({
                            'showModal': true,
                            'content': <PaymentManager debtId={this.props.debtId}/>
                        })}>
                            {t('Pagar Ahora')}
                        </button>
                    </Col>
                    <Col xs={{span: 12, offset: 0}} md={{span: 4, offset: 0}} className="flex-justify-content mb-4">
                        <button className='btn'
                                onClick={() => this.changeView('resolve')}>{t("Quiero resolverlo")}</button>
                    </Col>
                    <Col xs={{span: 12, offset: 0}} md={{span: 4, offset: 0}} className="flex-justify-content mb-4">
                        <button className='btn btnGris'
                                onClick={() => this.changeView('refuse')}>{t("No resolverlo")}</button>
                    </Col>
                </Row>
                <this.modalHandler/>
            </div>
        );
    }
}


StatusDebt.propTypes = {
    id: PropTypes.number.isRequired,
    expiredAt: PropTypes.string.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default StatusDebt;