import React, { Component } from 'react';
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import './moduleAvoidLiens.css';
import Resolve from "../ModuleFinal/Resolve";
import UnresolvedIncident from "../Incident/UnresolvedIncident";
import StatusDebt from "./StatusDebt";
import {postRefuse} from "../../api/Refuse";
import {t} from "i18next";

class ModuleAvoidLiens extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'main',
            items: [
                {icon: 'icons/invoice.svg', text: 'Facturación'},
                {icon: 'icons/bank.svg', text: 'Cuentas bancarias'},
                {icon: 'icons/home.svg', text: 'Propiedades'},
                {icon: 'icons/payroll.svg', text: 'Nóminas'},
                {icon: 'icons/tax_agency.svg', text: 'Agencia tributaria'}
            ],
        };
        this.changeView = this.changeView.bind(this);
    }

    changeView(viewSelected) {
        this.setState({ view: viewSelected });
    }

    listLiens (props) {
        const listItems = props.items.map((item) =>
            <div key={'element-' + item.icon}>
                <img className="icon-list-liens" src={item.icon} alt={item.icon} />
                <span>{t(item.text)}</span>
            </div>
        );
        return (
            <ul className='list_elements_liens'>{listItems}</ul>
        );

    }

    async refuse() {
        console.log('refuse method');
        await postRefuse(this.props.id);
        this.setState({view: 'refuse'})
    }

    render() {
        if (this.state.view === 'refuse') {
            return (<UnresolvedIncident />);
        } else if (this.state.view === 'resolve') {
            return <Resolve id={this.props.id} debtId={this.props.debtId} changeView={this.changeView } />;
        } else if (this.state.view === 'status') {
            return <StatusDebt id={this.props.id} expiredAt={this.props.expireAt} debtId={this.props.debtId} changeView={this.changeView } />;
        }
        return (
            <div className='body container_module'>
                <div className='container_title center text-center'>
                    <img src='/icons/danger.svg' alt='' />
                    <h1 className='title'>{t('Evitemos embargos de')}...</h1>
                </div>
                <Row>
                    <Col xs={{ span: 11, offset: 0 }} sm={{ span: 5, offset: 3 }} lg={{ span: 4, offset: 2 }} className="center">
                        <this.listLiens items={this.state.items} />
                    </Col>
                    <Col xs={{ span: 10, offset: 1 }} sm={{ span: 5, offset: 3 }} lg={{ span: 5, offset: 0 }} className="center">
                        <Row className="my-4">
                            <Col xs="12" className="flex-justify-content">
                                <button className='btn' onClick={() => this.setState({view: 'status'})}>{t('Estado deuda')}</button>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col xs="12" className="flex-justify-content">
                                <button className='btn' onClick={() => this.setState({view: 'resolve'})}>{t('Quiero resolverlo')}</button>
                            </Col>
                        </Row>
                        <Row className="my-4">
                            <Col xs="12" className="flex-justify-content">
                                <button className='btn btnGris' onClick={() => this.refuse()}>{t('No resolverlo')}</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
        );
    }
}

ModuleAvoidLiens.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
    expireAt: PropTypes.string.isRequired
};

export default ModuleAvoidLiens;