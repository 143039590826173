import { postApi } from './api';


export const postContact = (id, email, phone, from, to) => new Promise((resolve, reject) =>
{
    const data = {
        'email': email === '' ? null : email,
        'phone': phone === '' ? null : phone,
        'from': from.length > 0 ? from : '09:00',
        'until': to.length > 0 ? to : '17:00',
        'idInstancia': id
    };
    postApi('/addContacto', data)
        .then(data =>
            {
                return resolve(data);
            }
        ).catch(err => {
            return reject(err);
        });
});