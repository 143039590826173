import React, { Component } from 'react';
import Calendar from 'react-calendar';
import '../../utils/calendar.css';
import Loading from "../Loading";
import IncidentComplete from "../Incident/IncidentComplete";
import { Row, Col } from 'react-bootstrap'
import {updateDate} from "../../api/Instance";
import PropTypes from "prop-types";
import {t} from "i18next";

class DatePayment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sentSuccessfully: false,
            date: new Date(),
            maxDate: (new Date()).setDate((new Date()).getDate() + 15)
        };
        this.sendRequest = this.sendRequest.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
    }

    async sendRequest() {
        this.setState({ isLoading: true });
        try{
            await updateDate(this.props.id, this.state.date);
            this.setState({ isLoading: false, sentSuccessfully: true });
        } catch(error){
            this.setState({ error, isLoading: false });
        }
    }

    onChangeDate(value) {
        this.setState({ date: value });
    }

    render() {
        const { isLoading, sentSuccessfully, date, maxDate } = this.state;
        if (isLoading) {
            return <Loading />
        } else if (sentSuccessfully) {
            return <IncidentComplete
                instanceId={this.props.id}
                message="Esperamos tu pago en la fecha indicada"
            />
        }
        return (<React.Fragment>
            <Row className="justify-content-md-center mb-4">
                <Col xs="12" lg="6" className="flex-justify-content">
                    <Calendar minDate={new Date()} maxDate={new Date(maxDate)} onChange={this.onChangeDate} value={date}/>
                </Col>
                <Col xs="12" lg="6" className="vertical-center">
                    <p className="p_incident ml-4 my-4">{t('Seleccione una Nueva Fecha de pago y pulse aceptar')}</p>
                    <button className="btn" onClick={this.sendRequest}>{t('Aceptar')}</button>
                </Col>
            </Row>
        </React.Fragment>);
    }
}

DatePayment.propTypes = {
    id: PropTypes.number.isRequired
};

export default DatePayment;