import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function DebtList() {
  const [debts, setDebts] = useState([]);
  const [filter, setFilter] = useState([]);

  const urlDebts = "/api/Debts.json";

  const fetchDebts = () => {
    axios.get(urlDebts).then((result) => {
      const debts = result.data;
      setDebts(debts);
      setFilter(debts);
    });
  };

  const filterDebt = (e) => {
    let searchName = e.target.value;
    setFilter(
      debts.filter(
        (d) =>
          d.Importe.toLowerCase().includes(searchName) ||
          d.NIFDeudor.toLowerCase().includes(searchName) ||
          d.NIFAcreedor.toLowerCase().includes(searchName)
      )
    );
  };

  const updateDebt = (e) => {
    const isTrue = { isPaid: true };
    axios.put("/api/debts/" + e.target.value, isTrue);
  };

  useEffect(() => {
    fetchDebts();
  }, []);

  return (
    <div>
      <h1>Debt List</h1>
      <input
        type="text"
        placeholder="Busca una deuda"
        name="debtfilter"
        onChange={filterDebt}
      />

      <table>
        <thead>
          <tr>
            <th>Importe</th>
            <th>NIF del deudor</th>
            <th>NIF del acreedor</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody>
          {filter.map((debt) => (
            <tr key={debt.id}>
              <td>{debt.Importe}</td>
              <td>{debt.debtor}</td>
              <td>{debt.creditor}</td>
              <td>{debt.Fecha}</td>
              <td>
                <button>
                  <Link to="/" value={debt.id} onClick={updateDebt}>
                    {debt.isPaid ? "Pagado" : "No pagado"}
                  </Link>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default DebtList;
