import React, { Component } from 'react';
import Loading from "../Loading";
import IncidentComplete from "../Incident/IncidentComplete";
import PropTypes from "prop-types";
import {postContact} from "../../api/Contact";
import {Col, Row, Alert} from "react-bootstrap";
import {t} from "i18next";

const START = '9:00';
const FINISH = 17;

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sentSuccessfully: false,
            email: '',
            phone: '',
            from: '',
            to: '',
            fromData: [],
            toData: [],
            hasError: false,
            errMsg: '',
        };
        this.onChangeForm = this.onChangeForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.loadOptionsSelect(START, true);
    }

    loadOptionsSelect(start, setFrom) {
        const hour = start.split(':')[0];
        const from = [];
        const to = [];
        for (let i = hour; i <= FINISH; i++) {
            const time = i + ':00';
            from.push({ value: time, name: time });
            if (i !== hour) {
                to.push({ value: time, name: time });
            }
        }
        if (setFrom) {
            from.pop();
            this.setState({fromData: from});
        }
        this.setState({toData: to});
    }

    validation(app){
        let errMsg = '';
        if (app.email.length === 0 &&  app.phone.length === 0) {
            errMsg = 'Es necesario introducir un email o télefono para poder contactarte';
        }
        if (app.email.length > 0 && !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(app.email))) {
            errMsg = 'Debe introducir un email válido';
        } else if (app.phone.length > 0 && !(/^\+?(6\d{2}|7[1-9]\d{1})\d{6}$/.test(app.phone))) {
            errMsg = 'Debe introducir un teléfono válido';
        }
        return errMsg;
    }

    async handleSubmit() {
        if('' !== this.validation(this.state)){
            this.setState({hasError:true, errMsg: this.validation(this.state)});
            setTimeout(function(){ this.setState({hasError:false}); }.bind(this), 5000);
            return false;
        }
        this.setState({isLoading: true});
        try {
            await postContact(this.props.id, this.state.email, this.state.phone, this.state.from, this.state.to);
            this.setState({isLoading: false, sentSuccessfully: true});
        } catch (error) {
            this.setState({hasError:true, errMsg: 'Ha ocurrido un error, intentelo más tarde', isLoading: false});
            setTimeout(function(){ this.setState({errorMsg:'', hasError: false}); }.bind(this), 5000);
        }
    }

    onChangeForm(event) {
        const stateObject = function() {
            let returnObj = {};
            returnObj[this.target.id] = this.target.value;
            return returnObj;
        }.bind(event)();

        if (event.target.id === 'from') {
            this.loadOptionsSelect(event.target.value, false);
        }
        this.setState(stateObject);
    }

    render() {
        const { isLoading, sentSuccessfully, hasError } = this.state;
        if (isLoading) {
            return <Loading />
        } else if (sentSuccessfully) {
            return <IncidentComplete
                instanceId={this.props.id}
                message="Nos pondremos en contacto cuanto antes"
            />
        }
        return (<React.Fragment>
            <div className="modal_content">
                <p className="p_incident">{t('¿Cómo te contactamos?')}</p>
                { hasError && (<Alert variant="danger">{this.state.errMsg}</Alert>) }
                <Row className="justify-content-md-center mb-3">
                    <Col xs="12" lg="9">
                        <img src='icons/mail.svg' className='icon-input' alt='' />
                        <input type="email" id="email" placeholder={t('Córreo electrónico')} value={this.state.email} onChange={this.onChangeForm} />
                    </Col>
                    <Col xs="12" sm="4" lg="4">
                        <img src='icons/mobile.svg' className='icon-input icon-mobile' alt='' />
                        <input type="phone" id="phone" placeholder={t('Teléfono')} value={this.state.phone} onChange={this.onChangeForm} />
                    </Col>
                    <Col xs="12" sm="8" lg="5">
                        <Row>
                            <Col xs="6">
                                <select id="from" value={this.state.from} onChange={this.onChangeForm}>
                                    <option value="">{t("de")}</option>
                                    {this.state.fromData.map((e, key) => {
                                        return <option key={key} value={e.value}>{e.name}</option>;
                                    })}
                                </select>
                            </Col>
                            <Col xs="6">
                                <select id="to" value={this.state.to} onChange={this.onChangeForm}>
                                    <option value="">{t("a")}</option>
                                    {this.state.toData.map((e, key) => {
                                        return <option key={key} value={e.value}>{e.name}</option>;
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <button className="btn" onClick={this.handleSubmit}>{t('Aceptar')}</button>
            </div>
        </React.Fragment>);
    }
}

Contact.propTypes = {
    id: PropTypes.number.isRequired
};

export default Contact;