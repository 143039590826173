import axios from "axios";
import React from "react";

function CreateNewUser({
  userName,
  setUserName,
  userNIF,
  setUserNIF,
  userEmail,
  setUserEmail,
  userPassword,
  setUserPassword,
}) {
  const setUserNameHandler = (e) => {
    setUserName(e.target.value);
  };

  const setUserNIFHandler = (e) => {
    setUserNIF(e.target.value);
  };

  const setUserEmailHandler = (e) => {
    setUserEmail(e.target.value);
  };

  const setUserPasswordHandler = (e) => {
    setUserPassword(e.target.value);
  };

  const createNewUser = (e) => {
    e.preventDefault();

    const newUser = JSON.stringify({
      name: userName,
      dni: userNIF,
      mail: userEmail,
      pin: userPassword,
    });

    axios.post("/api/users", newUser).then((response) => {
      setUserName = response.data.userName;
      setUserNIF = response.data.userNIF;
      setUserEmail = response.data.userEmail;
    });

    var config = {
      method: "post",
      url: "/api/users.json",
      headers: {
        "Content-Type": "application/json",
      },
      data: newUser,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <h1>Crear nuevo usuario</h1>
      <form>
        <label htmlFor=""> Nombre de la persona</label>
        <input
          type="text"
          placeholder="Escriba aquí su nombre..."
          value={userName}
          onChange={setUserNameHandler}
        />
        <label htmlFor="">DNI</label>
        <input
          type="text"
          placeholder="Escriba aquí su DNI..."
          value={userNIF}
          onChange={setUserNIFHandler}
        />
        <label htmlFor=""> Email </label>
        <input
          type="text"
          placeholder="Escriba aquí su email..."
          value={userEmail}
          onChange={setUserEmailHandler}
        />
        <label htmlFor="">Contraseña</label>
        <input
          type="text"
          placeholder="Escriba aquí su contraseña..."
          value={userPassword}
          onChange={setUserPasswordHandler}
        />
        <button type="submit" onClick={createNewUser}>
          Crear nuevo usuario
        </button>
      </form>
    </div>
  );
}

export default CreateNewUser;
