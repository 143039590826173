import { postApi } from './api';

export const postRefuse = (id) => new Promise((resolve) =>
{
    postApi('/instance/'+id+'/refuse', {})
        .then(data =>
            {
                return resolve(data);
            }
        );
});