import React, { Component } from 'react';
import Loading from "../Loading";
import IncidentComplete from "../Incident/IncidentComplete";
import PropTypes from "prop-types";
import {postIncident} from "../../api/Incident";
import {t} from "i18next";

const TYPE = 102;
const MESSAGE = 'notificación de incidencia de facturación';

class BillingIncident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sentSuccessfully: false,
        };
        this.sendRequest = this.sendRequest.bind(this);
    }

    async sendRequest() {
        this.setState({isLoading: true});
        try {
            await postIncident(this.props.id, TYPE, MESSAGE);
            this.setState({isLoading: false, sentSuccessfully: true});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    render() {
        const { isLoading, sentSuccessfully } = this.state;
        if (isLoading) {
            return <Loading />
        } else if (sentSuccessfully) {
            return <IncidentComplete
                instanceId={this.props.id}
                message="Has notificado una incidencia con la facturación, nos ponemos en contacto cuanto antes"
            />
        }
        return (<React.Fragment>
            <div className="modal_content">
                <p className="p_incident left">{t('Vas a notificar una incidencia con la facturación')}</p>
                <button className="btn mt-4" onClick={this.sendRequest}>{t('Aceptar')}</button>
            </div>
        </React.Fragment>);
    }
}

BillingIncident.propTypes = {
    id: PropTypes.number.isRequired
};

export default BillingIncident;