import './Gracias.css'
import AppsMobile from "../../layouts/AppsMobile";

function Gracias() {
    return (
        <div className="container_body gracias">
            <div className="gracias_s1">
                <h1 className="title">¡Gracias!</h1>
                <div className="center">
                    <img src="figures/complete.svg" alt=""/>
                </div>
            </div>
            <AppsMobile message="Gestiona mejor tus cobros y pagos desde nuestra App" />
        </div>
    )
}

export default Gracias
