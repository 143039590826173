import React, { Component } from 'react';
import ModalComponent from "../ModalComponent";
import PaymentManager from "../PaymentManager";
import PropTypes from "prop-types";
import MenuDesktop from "../Menu/MenuDesktop";
import {Col, Row} from "react-bootstrap";
import './moduleConflict.css';
import {t} from "i18next";

class ModuleConflict extends Component {
    constructor(props) {
        super(props);
        const expireIn = Math.abs(new Date(props.expireAt) - new Date());
        this.state = {
            showModal: false,
            typeModal: null,
            remainingDays: parseInt(expireIn/(1000 * 3600 * 24)) + 1
        };
        this.modalHandler = this.modalHandler.bind(this);
        this.itemSelectedHandler = this.itemSelectedHandler.bind(this);
    }

    modalHandler() {
        const { showModal, typeModal } = this.state;
        return (
            <ModalComponent show={showModal} onHide={() => this.setState({ showModal: false })}>
                {typeModal}
            </ModalComponent>
        );
    }

    itemSelectedHandler(item) {
        if (typeof item.to !== 'undefined') {
            this.props.loadModule(item.to);
        } else {
            this.setState({ typeModal: item.modal, showModal: true });
        }
    }

    render() {
        return (
            <div className='body'>
                <div className='container_title center text-center'>
                    <img src='/icons/danger.svg' alt='' />
                    <h1 className='title'>{t('Evitemos un conflicto')}</h1>
                </div>
                <div className='container_module'>
                    <Row>
                        <Col xs={{ span: 10, offset: 1, order: 2 }} lg={{ span: 6, offset: 0, order: 1 }}>
                            <div className='container_section pb-4'>
                                <div className="text-center">
                                    <div className="p_incident mt-4">{t('Días para evitar la reclamación')}</div>
                                    <div className="remainingDays my-1">{this.state.remainingDays}</div>
                                    <button className='btn my-4' onClick={
                                        () => this.setState({ typeModal: <PaymentManager debtId={this.props.debtId} />, showModal: true })
                                    }>
                                        {t('Pagar Ahora')}
                                    </button>
                                    <Row>
                                        <Col xs={{ span: 10, offset: 2 }} sm={{ span: 7, offset: 3 }}>
                                            <MenuDesktop id={this.props.id} debtId={this.props.debtId} type="ModuleConflict" itemSelected={this.itemSelectedHandler} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 10, offset: 1, order: 1 }} lg={{ span: 5, offset: 0, order: 2 }}>
                            <div className='container_section' id='contenedor-izquierda'>
                                <div className='center mt-4'>
                                    <img src='/figures/judge.svg' alt='' className="image-judge" />
                                </div>
                                <div className='p_incident text-center center mt-4 description-conflict'>
                                    {t('No resolver su situación en el plazo indicado generará una reclamación judicial del importe pendiente, más los perjuicios causados')}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <this.modalHandler />
            </div>
        );
    }
}

ModuleConflict.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
    expireAt: PropTypes.string.isRequired
};

export default ModuleConflict;