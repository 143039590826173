import {useState} from "react";
import CreateNewUser from "./CreateNewUser";
import UserList from "./UserList";

function UserController() {
    const [userName, setUserName] = useState("")
    const [userNIF, setUserNIF] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")

    return (
        <div className="user-controller">

            <CreateNewUser
                userName={userName}
                userNIF={userNIF}
                userEmail={userEmail}
                userPassword={userPassword}
                setUserName={setUserName}
                setUserNIF={setUserNIF}
                setUserEmail={setUserEmail}
                setUserPassword={setUserPassword}
            />

            <UserList/>
        </div>
    )
}

export default UserController
