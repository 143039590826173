import React from "react";
import DatePayment from "../ModuleOne/DatePayment";
import ServiceIncident from "../ModuleOne/ServiceIncident";
import BillingIncident from "../ModuleOne/BillingIncident";
import OtherIncident from "../ModuleOne/OtherIncident";
import LiquidityIncident from "../ModuleTwo/LiquidityIncident";
import Contact from "../ModuleTwo/Contact";
import MakeDeal from "../ModuleTwo/MakeDeal";


const ItemsModuleOne = (id) => ([
    {
        'key': 'new-date',
        'icon': 'icons/new_date.svg',
        'title': 'Nueva fecha de pago',
        'modal': <DatePayment id={id} />
    },
    {
        'key': 'incidence-product',
        'icon': 'icons/incidence_product.svg',
        'title': 'Incidencias con el producto / servicio',
        'modal': <ServiceIncident id={id} />
    },
    {
        'key': 'incidence-invoice',
        'icon': 'icons/incidence_invoice.svg',
        'title': 'Incidencias con la facturación',
        'modal': <BillingIncident id={id} />
    },
    {
        'key': 'other',
        'icon': 'icons/other.svg',
        'title': 'Otro',
        'modal': <OtherIncident id={id} />
    },
]);
const ItemsModuleTwo = (id, debtId) => ([
    {
        'key': 'incidence_date',
        'icon': 'icons/incidence_date.svg',
        'title': 'Nueva fecha de pago o incidencia',
        'to': 'module-pre-two'
    },
    {
        'key': 'liquidity',
        'icon': 'icons/liquidity.svg',
        'title': 'Falta de liquidez puntual',
        'modal': <LiquidityIncident id={id} />
    },
    {
        'key': 'deal',
        'icon': 'icons/create_deal.svg',
        'title': 'Crear acuerdo de pago',
        'modal': <MakeDeal id={id} debtId={debtId} />
    },
    {
        'key': 'contact',
        'icon': 'icons/other.svg',
        'title': '¡Quiero resolverlo! Solicito contacto',
        'modal': <Contact id={id} />
    },
]);

export const getItems = (id, debtId, type) => {
    let items = [];
    if (type === 'ModuleOne') {
        items = ItemsModuleOne(id);
    } else if (type === 'ModuleTwo') {
        items = ItemsModuleTwo(id, debtId);
    } else if (type === 'ModulePreTwo') {
        items = ItemsModuleOne(id);
        items.pop();
        items.push({
            'key': 'go_back',
            'icon': 'icons/go_back.svg',
            'title': 'Ir atrás',
            'to': 'module-two'
        });
    } else if (type === 'ModuleConflict') {
        items.push({
            'key': 'deal',
            'icon': 'icons/create_deal.svg',
            'title': 'Crear acuerdo de pago',
            'modal': <MakeDeal id={id} debtId={debtId} />
        });
    }
    return  items;
}