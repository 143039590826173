import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import './i18n';
import {Suspense} from 'react';


ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Loading/>}>
            {cambiaColor()}
            <App/>
        </Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);

function Loading() {
    return <h2>🌀 Loading...</h2>;
}

function cambiaColor() {
    let root = document.documentElement;
    root.style.setProperty('--color-principal', process.env.REACT_APP_COLOR);
    root.style.setProperty('--color-secundario', process.env.REACT_APP_COLOR2);
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
