import React, { Component } from 'react';
import Loading from "../Loading";
import IncidentComplete from "../Incident/IncidentComplete";
import PropTypes from "prop-types";
import {postIncident} from "../../api/Incident";
import {t} from "i18next";

const TYPE = 200;

class OtherIncident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sentSuccessfully: false,
            message: ''
        };
        this.sendRequest = this.sendRequest.bind(this);
        this.onChangeMessage = this.onChangeMessage.bind(this);
    }

    async sendRequest() {
        this.setState({isLoading: true});
        try {
            await postIncident(this.props.id, TYPE, this.state.message);
            this.setState({isLoading: false, sentSuccessfully: true});
        } catch (error) {
            this.setState({error, isLoading: false});
        }
    }

    onChangeMessage(event) {
        this.setState({ message: event.target.value });
    }

    render() {
        const { isLoading, sentSuccessfully } = this.state;
        if (isLoading) {
            return <Loading />
        } else if (sentSuccessfully) {
            return <IncidentComplete
                instanceId={this.props.id}
                message="Nos has facilitado la descripción de tu incidencia de pago, nos ponemos en contacto cuanto antes"
            />
        }
        return (<React.Fragment>
            <div className="modal_content">
                <p className="p_incident">{t('Coméntanos cuál es el motivo de tu incidencia de pago')}</p>
                <textarea className="ta_incident" rows="4" placeholder={t('Describe tu incidencia') + "..."} value={this.state.message} onChange={this.onChangeMessage}/>
                <button className="btn" onClick={this.sendRequest}>{t('Enviar')}</button>
            </div>
        </React.Fragment>);
    }
}

OtherIncident.propTypes = {
    id: PropTypes.number.isRequired
};

export default OtherIncident;