import CreateNewDebt from "./CreateNewDebt";
import DebtList from "./DebtList";

function DebtController() {
    return (
        <div>
            <CreateNewDebt/>
            <DebtList/>
        </div>
    )
}

export default DebtController
