import React, { Component } from 'react';
import "./Menu.css";
import PropTypes from "prop-types";
import {getItems} from "./index";
import {t} from "i18next";


class MenuDesktop extends Component {
    constructor(props) {
        super(props);
        this.menuList = this.menuList.bind(this);
    }

    menuList(props) {
        const listItems = props.items.map((item) =>
            <li key={'item_'+ item.key} className="mb-4">
                <div className='link_image' onClick={
                    () => {
                        this.props.itemSelected(item)
                    }
                }>
                    <div className="rounded_border" />
                    <img className={`icon icon_${item.key}`} src={item.icon} alt='' />
                    <span>{t(item.title)}</span>
                </div>
            </li>
        );
        return (
            <div>
                <ul className='list_elements'>{listItems}</ul>
            </div>
        );
    }

    render() {
        return(
            <React.Fragment>
                <this.menuList items={getItems(this.props.id, this.props.debtId, this.props.type)} />
            </React.Fragment>
        );

    }
}

MenuDesktop.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default MenuDesktop;