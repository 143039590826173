import React from "react";
import "./Welcome.css";
import { useLocation } from "react-router-dom";
import WelcomeComponent from '../../components/Welcome';
import DeniedAccess from "../../layouts/DeniedAccess";

export default function Welcome() {
	const search = useLocation().search;
	let data = JSON.parse(new URLSearchParams(search).get("data"));

	if (null === data || typeof data.token === 'undefined' || typeof data.instancia === 'undefined') {
		return (<DeniedAccess />);
	}

	if (null !== new URLSearchParams(search).get("openTpv")) {
		localStorage.setItem('openTpv', "1");
	}

	localStorage.setItem('accessToken', data.token);

	return (
		<WelcomeComponent id={data.instancia} />
	);
}
