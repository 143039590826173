import React from 'react';
import './Layouts.css';
import {t} from "i18next";

const Thanks = () => (
    <div className="container_section gracias_s2">
        <div className="center">
            <h1 className="title thanks_for_using">
                {t('Gracias por usar')}
                <img src="/logo512.png" alt="" className="logo_ezpays"/>
            </h1>
        </div>
    </div>
);

export default Thanks;
