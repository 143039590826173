import React, {Component} from 'react';
import PropTypes from "prop-types";
import {getInstance} from "../../api/Instance";
import {getDebt} from "../../api/Debt";

class IncidentComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toFinish: false,
            debt: null,
        };
    }

    async componentDidMount() {
        try {
            const instance = await getInstance(this.props.instanceId);
            const debt = instance ? await getDebt(instance.assignedDebt) : null;
            this.setState({ debt });
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            this.setState({toFinish: true});
        }, 5000);
    }

    componentWillUnmount() {
        localStorage.removeItem('accessToken');
    }

    render() {
        if (this.state.toFinish) {
            window.location.reload();
        }
        return (<React.Fragment>
            <div className="modal_content">
                <p className="p_incident">{this.props.message}</p>
                <img className='image mt-4' src='/icons/deal.svg' alt='' />
            </div>
        </React.Fragment>);
    }
}

IncidentComplete.propTypes = {
    instanceId: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired
};

export default IncidentComplete;