import { postApi } from './api';

export const postDeal = (instanceId, initialPayment, numberPayment, paidDebtAmount, code) => new Promise((resolve, reject) =>
{
    const data = {
        'initialPayment': parseFloat(initialPayment),
        'numPayments': parseInt(numberPayment),
        'instanceId': instanceId,
        'paidDebtAmount': paidDebtAmount,
        'code': code
    };

    postApi('/acuerdos', data)
        .then(data => {
            return resolve(data);
        }).catch(err => {
            return reject(err);
        });
});

export const generateCode = (instanceId) => new Promise((resolve, reject) =>
{
    postApi('/instance/'+instanceId+'/code', {})
        .then(data => {
            return resolve(data);
        }).catch(err => {
            return reject(err);
        });
});