import "./App.scss";
import {AuthProvider} from "./context";
import RouteList from "./routes/index"

function App() {
    return (
        <AuthProvider>
            <div className='App'>
                <RouteList/>
            </div>
        </AuthProvider>
    );
}

export default App;
