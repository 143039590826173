import "./Footer.css";

function Footer() {
	return (
		<div className='footer'>
			<div className='footerItems'>
				<img className='corpLogo' src={process.env.REACT_APP_CORP_LOGO} alt=''/>
			</div>
			<div className='footerItems'>
				{/* <span className='footerLogoItem'>by</span>
				<img className='logo footerLogoItem' src='/icons/owe.svg' alt=''/> */}
			</div>
		</div>
	);
}

export default Footer;
