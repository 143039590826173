import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import './Layouts.css';
import Thanks from "./Thanks";
import {t} from "i18next";

class DeniedAccess extends Component {
    render() {
        return (
            <div className='text-center'>
                <Row className="justify-content-md-center mb-5">
                    <Col xs="6">
                        <div className="forbidden">
                            <h1 className="title">{this.props.message ? this.props.message : t('No tiene permiso para acceder')}</h1>
                        </div>
                    </Col>
                    <Col>
                        <img src='/figures/failed.svg' alt='' />
                    </Col>
                </Row>
                <Thanks />
            </div>
        );
    }
}

DeniedAccess.propTypes = {
    message: PropTypes.string
};

export default DeniedAccess;