import React, { useEffect, useState } from "react";
import axios from "axios";

function CreateNewDebt() {
  const [users, setUsers] = useState([]);

  const urlUsers = "/api/Users.json";

  const fetchUsers = () => {
    axios.get(urlUsers).then((res) => {
      const users = res.data;
      console.log(users);
      setUsers(users);
    });
  };
  
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <h1>Crear nueva deuda</h1>
      <form action="">
        <label htmlFor="">Importe</label>
        <input type="number" step="0.01" placeholder="0.00 €" />
        <label htmlFor="">DNI del acreedor</label>
        <select name="select1" id="select1">
          <option value="empty1" disabled defaultValue>
            Elija una opción
          </option>

          {users.map((user) => (
            <option value={user.dni} key={user.id}>
              {user.dni} - {user.name}
            </option>
          ))}
        </select>
        <label htmlFor="">DNI del deudor</label>
        <select name="select2" id="select2">
          <option value="empty2" disabled defaultValue>
            Elija una opción
          </option>

          {users.map((user) => (
            <option value={user.dni} key={user.id}>
              {user.dni} - {user.name}
            </option>
          ))}
        </select>
        <label htmlFor="emisionDate">Fecha de emision</label>
        <input type="date" />
        <label htmlFor="emisionDate">Fecha de finalización</label>
        <input type="date" />
        <button type="submit">Crear nueva deuda</button>
      </form>
    </div>
  );
}

export default CreateNewDebt;
