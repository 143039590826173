import React, { useEffect, useState } from "react";
import axios from "axios";

function UserList() {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);

  const urlUsers = "/api/Users.json";

  const fetchUsers = () => {
    axios.get(urlUsers).then((res) => {
      const users = res.data;
      console.log(users);
      setUsers(users);
      setFilter(users);
    });
  };

  const filterUser = (e) => {
    let searchName = e.target.value;
    setFilter(
      users.filter(
        (u) =>
          u.name.toLowerCase().includes(searchName) ||
          u.mail.toLowerCase().includes(searchName) ||
          u.dni.toLowerCase().includes(searchName)
      )
    );
  };

  const deleteUser = (e) => {
    axios.delete("/api/users/" + e.target.value);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div>
      <h1>User List</h1>
      <input
        type="text"
        placeholder="Busca un ususario"
        name="userfilter"
        onChange={filterUser}
      />

      <table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Email</th>
            <th>NIF</th>
          </tr>
        </thead>

        <tbody>
          {filter.map((user) => (
            <tr key={user.id}>
              <td>{user.name}</td>
              <td>{user.mail}</td>
              <td>{user.dni}</td>
              <td>
                <button value={user.id} onClick={deleteUser}>
                  Eliminar usuario
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserList;
