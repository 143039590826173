import React from "react";

export default function LoginAdmin() {
	return (
		<form>
			<label>Usuario</label>
			<input type='text' placeholder='' />
			<label>Contraseña</label>
			<input type='text' placeholder='' />
			<button type='submit'>Login</button>
		</form>
	);
}
