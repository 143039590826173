import React, { Component } from 'react';
import PropTypes from "prop-types";
import './moduleFinal.css';
import ModuleFinalMain from "./ModuleFinalMain";
import Resolve from "./Resolve";

class ModuleFinal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: 'main'
        };
        this.changeView = this.changeView.bind(this);
    }

    changeView(viewSelected) {
        this.setState({ view: viewSelected });
    }

    render() {
        if (this.state.view === 'main') {
            return (<ModuleFinalMain id={this.props.id} debtId={this.props.debtId} changeView={this.changeView }/>);
        }
        return <Resolve id={this.props.id} debtId={this.props.debtId} changeView={this.changeView } />;
    }
}

ModuleFinal.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default ModuleFinal;