import React, {Component} from 'react';
import PropTypes from "prop-types";
import {getDebt} from "../api/Debt";
import {Alert} from "react-bootstrap";
import {Redirect} from "react-router-dom";
import {t} from "i18next";

class PaymentManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            makingPayment: false,
            url: '',
            errorMsg: ''
        };
        this.goToTPV = this.goToTPV.bind(this);
    }

    parseUrl(link) {
        return link.includes('http') ? link : 'https://' + link;
    }

    async componentDidMount() {
        try {
            const debt = await getDebt(this.props.debtId);
            this.setState({url: debt !== null ? this.parseUrl(debt.link) : ""});
        } catch (error) {
            this.setState({errorMsg: 'Ha ocurrido un error al intentar obtener su deuda, intentelo más tarde'});
            setTimeout(function(){ window.location.reload(); }, 5000);
        }
    }

    goToTPV() {
        if (null === localStorage.getItem('openTpv')) {
            window.location.replace(this.state.url);
        } else {
            localStorage.removeItem('openTpv');
            window.open(this.state.url, "_blank");
        }
        this.setState({makingPayment: true});
    }

    render() {
        if (this.state.makingPayment) {
            return <Redirect to='/waiting' />
        } else {
            return (<React.Fragment>
                <div className="modal_content">
                    { this.state.errorMsg !== '' && (<Alert variant="danger">{this.state.errorMsg}</Alert>) }
                    <p className="p_incident">{t('Vas a realizar el pago')}</p>
                    <button className="btn mt-4" disabled={this.state.errorMsg !== ''} onClick={this.goToTPV}>
                        {t('Aceptar')}
                    </button>
                </div>
            </React.Fragment>);
        }
    }
}

PaymentManager.propTypes = {
    debtId: PropTypes.string.isRequired
};


export default PaymentManager;
