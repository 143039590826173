import '../../components/Welcome/form.css';
import React, { Component } from 'react';
import DeniedAccess from "../../layouts/DeniedAccess";
import ModalComponent from "../ModalComponent";
import PaymentManager from "../PaymentManager";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import {postRefuse} from "../../api/Refuse";
import {t} from "i18next";

class ModuleFinalMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRefuse: false,
            showModal: false,
        };
        this.modalHandler = this.modalHandler.bind(this);
        this.changeView = this.changeView.bind(this);
    }

    modalHandler() {
        const { showModal } = this.state;
        return (
            <ModalComponent show={showModal} onHide={() => this.setState({ showModal: false })}>
                <PaymentManager debtId={this.props.debtId}/>
            </ModalComponent>
        );
    }

    changeView() {
        this.props.changeView('resolve');
    }

    async refuse(instanceId) {
        await postRefuse(instanceId);
        this.setState({'showRefuse': true});
    }

    render() {
        if (this.state.showRefuse) {
            return (<DeniedAccess message="¡Lo hemos intentado!" />);
        }
        return (
            <div className='body'>
                <div className='container_title'>
                    <h1 className='titulo title_module_final'>{t('¿Quiero resolver mi incidencia de pago?')}</h1>
                </div>
                <Row className="mx-1">
                    <Col xs="6" sm={{ span: 4, offset: 2 }} className="flex-justify-content">
                        <button className='btn' onClick={this.changeView}>{t('Si')}</button>
                    </Col>
                    <Col xs="6" sm="4" className="flex-justify-content">
                        <button className='btn btnGris' onClick={() => this.refuse(this.props.id)}>{t('No')}</button>
                    </Col>
                </Row>


                <div className='container_body'>
                    <div className='container_section'>
                        <div className='image centrar_imagen'>
                            <img src='./figures/woman.svg' alt='image_woman' className='image_woman' />
                        </div>
                        <div className='centrar_imagen'>
                            <button className='btn' onClick={() => this.setState({'showModal': true})}>{t('Pagar Ahora')}</button>
                        </div>
                    </div>
                </div>
                <this.modalHandler />
            </div>
        );
    }
}

ModuleFinalMain.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default ModuleFinalMain;