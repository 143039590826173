import React, { Component } from 'react';
import ModalComponent from "../ModalComponent";
import PaymentManager from "../PaymentManager";
import PropTypes from "prop-types";
import MenuDesktop from "../Menu/MenuDesktop";
import {Col, Row} from "react-bootstrap";
import './moduleOne.css';
import {t} from "i18next";

class ModuleOneMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            typeModal: null,
        };
        this.itemSelectedHandler = this.itemSelectedHandler.bind(this);
        this.modalHandler = this.modalHandler.bind(this);
    }

    itemSelectedHandler(item) {
        this.setState({ typeModal: item.modal, showModal: true });
    }

    modalHandler() {
        const { showModal, typeModal } = this.state;
        return (
            <ModalComponent show={showModal} onHide={() => this.setState({ showModal: false })}>
                {typeModal}
            </ModalComponent>
        );
    }

    render() {
        return (
            <div className='body'>
                <div className='container_title'>
                    <h1 className='title'>{t('')}</h1>
                </div>
                <div className='container_module'>
                    <Row>
                        <Col xs={{ span: 10, offset: 1, order: 2 }} lg={{ span: 6, order: 1 }}>
                            <div className='container_section pb-4'>
                                <h2 className='subtitulo title_menu pb-4'>{t('Selecciona')}</h2>
                                <MenuDesktop id={this.props.id} debtId={this.props.debtId} type="ModuleOne" itemSelected={this.itemSelectedHandler} />
                            </div>
                        </Col>
                        <Col xs={{ span: 10, offset: 1, order: 1 }} lg={{ span: 4, offset: 0, order: 2 }}>
                            <div className='container_section' id='contenedor-izquierda'>
                                <div className='center mb-4'>
                                    <img src='/figures/deal.svg' alt='deal' className="image-deal" />
                                </div>
                                <button className='btn my-4' onClick={
                                    () => this.setState({ typeModal: <PaymentManager debtId={this.props.debtId}/>, showModal: true })
                                }>
                                    {t('Pagar Ahora')}
                                </button>
                                <p className='module-comment-figure mt-2 center'>{t('Ayúdanos a resolver tu incidencia de pago')}</p>
                            </div>
                        </Col>
                    </Row>
                </div>
                <this.modalHandler />
            </div>
        );
    }
}

ModuleOneMain.propTypes = {
    id: PropTypes.number.isRequired,
    debtId: PropTypes.string.isRequired,
};

export default ModuleOneMain;