import { postApi } from './api';

export const postIncident = (id, type, message) => new Promise((resolve) =>
{
    if (document.getElementsByClassName('btn-close').length > 0) {
        document.getElementsByClassName('btn-close')[0].style.display = 'none';
    }
    const data = {
        'tipo': type,
        'message': message,
        'instancia': id
    };
    postApi('/incidencias', data)
        .then(data =>
            {
                return resolve(data);
            }
        );
});